@import-normalize;

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Calibre";
  font-weight: 400;
  font-size: 1.125rem;
  color: #32323C;
  line-height: 1.5625rem;
}

#root {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
